import { useMutation } from 'react-query';

import feathersClient from '../../../../apiClient';
import { SecondaryButton } from '../../../../components/buttons';

export interface AcceptInvitationButtonProps {
  shiftId: number;
  personId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
  onError?: (error: unknown, variables: any, context: unknown) => void | Promise<unknown>;
}

const AcceptInvitationButton = ({
  shiftId: shift_id,
  personId: person_id,
  onSuccess,
  onError,
}: AcceptInvitationButtonProps) => {
  const { mutateAsync: patchInvitation } = useMutation(
    ['claim-shift', { id: shift_id }],
    async () => {
      await feathersClient
        .service('claim-shift')
        .create({ shift_id, person_id });
    },
    {
      onSuccess,
      onError,
    },
  );
  return (
    <div className="p-2 text-right">
      <SecondaryButton onClick={patchInvitation}>
        Přijmout pozvánku
      </SecondaryButton>
    </div>
  );
};
export default AcceptInvitationButton;
