import { StatKod as CountryCodes } from '@tymbe/mpsv/enums/statKod.enum';

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum ApplicationState {
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
  SYSTEM_CANCELED = 'system_canceled',
  CANCELED_EARLY = 'canceled_early',
  CANCELED_LATE = 'canceled_late',
  CANCELED = "canceled",
}

export const enum AttendanceResolution {
  UNEXCUSED_ABSENCE = 'unexcused_absence',
  ADMIN_EXCUSED_ABSENCE = 'admin_excused_absence',
  EXCUSED_ABSENCE = 'excused_absence',
  CANCEL = 'cancel',
  OK = 'ok',
  BREACH_OF_DISCIPLINE = 'breach_of_discipline',
}

export const enum DocumentType {
  ID_CARD = 'id_card',
  HEALTH_CARD = 'health_card',
  CRIMINAL_RECORD = 'criminal_record',
  DRIVING_LICENSE = 'driving_license',
  CONTRACT_DPP = 'contract_dpp',
  CONTRACT_DPC = 'contract_dpc',
  CONTRACT_HPP = 'contract_hpp',
  TYMBE_CONTRACT = 'tymbe_contract',
  CONTRACT_DPP_TEMPLATE = 'contract_dpp_template',
  CONTRACT_DPC_TEMPLATE = 'contract_dpc_template',
  CONTRACT_HPP_TEMPLATE = 'contract_hpp_template',
  DODP_TEMPLATE = 'dodp_template',
  PINKIE = 'pinkie',
  OTHER = 'other',
  MEDICAL_EXAMINATION = 'medical_examination',
}

export const enum DocumentCategory {
  WAGE_RELATED = 'wage_related',
}

export const ContractDocumentTypes = [
  DocumentType.CONTRACT_DPP,
  DocumentType.CONTRACT_DPC,
  DocumentType.CONTRACT_HPP,
];

export const enum DocumentValidityStart {
  SHIFT_START = 'shift_start',
  ON_SIGN = 'on_sign',
}

export const enum DocumentValidityEnd {
  DAY_END = 'day',
  MONTH_END = 'month',
  YEAR_END = 'year',
}

export const enum DocumentValidityDurationUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum CompanyState {
  PENDING = 'pending',
  ACTIVE = 'active',
  BANNED = 'banned',
}

export const enum CompanyType {
  REGULAR = 'regular',
  CONTRACTOR = 'contractor',
  SUBCONTRACTOR = 'subcontractor',
}

export const enum CompanyFulfillmentType {
  MANDATE = 'mandate',
  SERVICE = 'service',
  AGENCY = 'agency',
}

export const enum SalaryAdvanceRestrictionStatus {
  MIN_ENABLED = 'min_enabled', // minimální nezabavitelná částka
  FORBIDDEN = 'forbidden', // zakázáno (0)
}

export const enum NoteType {
  NOTE = 'note',
  ORDER_DETAILS = 'order_details',
}

export const enum BranchofficeType {
  AREA = 'area',
  BRANCH = 'branch',
  DEPARTMENT = 'department',
  VACATION = 'vacation',
}

export const enum SurveyQuestionType {
  TEXT = 'text',
  LONG_TEXT = 'longtext',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DROPDOWN = 'dropdown',
}

export const enum PerkGroup {
  AGE = 'age',
  SEX = 'sex',
  QUALIFICATION = 'QUALIFICATION',
  NATIONALITY = 'nationality',
}

export const enum Perk {
  MAN_ONLY = 'man_only',
  WOMAN_ONLY = 'woman_only',
  ADULT = 'adult',
  EU_CITIZEN = 'eu_citizen',
  INFORMATION_CARD = 'information_card',

  /** @deprecated */
  NON_EU_CITIZEN = 'non_eu_citizen',
  NON_UA_PROTECTED = 'non_ua_protected',
  COMPANY_JUNIOR = 'company_junior',
  COMPANY_SENIOR = 'company_senior',
  BRANCHOFFICE_SENIOR = 'branchoffice_senior',
  POSITION_SENIOR = 'position_senior',

  CACHIER = 'cachier',
  BILLA_SERVICE_DESK = 'billa_service_desk',
  BILLA_BAKERY = 'billa_bakery',

  MEDICAL_EXAMINATION = 'medical_examination',
}

export const enum ContactType {
  EMAIL = 'email',
  MOBILE_PHONE = 'mobile_phone',
  LANDLINE = 'landline',
  OTHER = 'other',
}

export const enum NotificationType {
  LAST_MINUTE_APPLICATION_CHANGE = 'last_min_app_change',
  PERSON_DOCUMENT_VALIDATED = 'person_document_validated',
  TYMBER_SHIFT_INVITE = 'tymber_shift_invite',
  COMPANY_BLOCKED_USER = 'company_blocked_user',
  BRANCHOFFICE_BLOCKED_USER = 'branchoffice_blocked_user',
  DEPARTMENT_BLOCKED_USER = 'department_blocked_user',
  UNEXCUSED_ABSENCE = 'unexcused_absence',
  REJECTED_EXCUSE = 'rejected_excuse',
  CANCELED_LATE_SYSTEM_REJECT = 'canceled_late_system_reject',
  UNEXCUSED_ABSENCE_SYSTEM_REJECT = 'unexcused_absence_system_reject',
  EXCUSE_CLARIFICATION_NEEDED = 'excuse_clarification_needed',
  APPROVED_EXCUSE = 'approved_excuse',
  ACCOUNT_ACTIVATION = 'account_activation',
  NEW_SIGNED_CONTRACT = 'new_signed_contract',
  RESET_PASSWORD_DONE = 'reset_password_done',
  RESET_PASSWORD = 'reset_password',
  WELCOME_TYMBER = 'welcome_tymber',
  AUTO_BAN = 'auto_ban',
}

export const enum Roles {
  SUPER_ADMIN = 'super_admin',
  TYMBE_ADMIN = 'tymbe_admin',
  ADMIN = 'admin',
  TYMBE_COORDINATOR = 'tymbe_coordinator',
  COMPANY = 'company_admin',
  BRANCHOFFICE_MANAGER = 'branchoffice_manager',
  BRANCHOFFICE_SUPERVISOR = 'branchoffice_supervisor',
  SHIFT_SUPERVISOR = 'shift_supervisor',
  TYMBER = 'tymber',
}

export const enum AuthManagementActionTypes {
  VERIFY_SIGNUP = 'verifySignup',
  GET_USER_BY_TOKEN = 'getUserByToken',
  SEND_VERIFY_SIGNUP = 'sendVerifySignup',
  RESET_PASSWORD = 'resetPassword',
  SEND_RESET_PASSWORD = 'sendResetPassword',
  SEND_BANNED_SHIFTS_INFO = 'sendBannedShiftsInfo',
  VERIFY_PASSWORD = 'verifyPassword',
  SEND_APPLICATION_SYSTEM_CANCELED_INFO = 'sendApplicationSystemCanceledInfo',
}

export const enum JobClassificationType {
  TYMBE = 'tymbe',
}

export const enum PersonAccountStateEnum {
  ADMIN = 'admin',
  COMPANY = 'company',
  BAN = 'ban',
  UNVERIFIED_ACCOUNT = 'unverified_account',
  UNFINISHED_REGISTRATION = 'unfinished_registration',
  UNVERIFIED_PROOF_OF_IDENTITY = 'unverified_proof_of_identity',
  ACTIVE = 'active',
}

export const enum IntegrationEventEnum {
  SYNC = 'sync',
  QUEUE = 'queue',
  CREATE = 'create',
  UPDATE = 'update',
  REMOVE = 'remove',
  CHANGE = 'change',
}

export const enum IntegrationEntityEnum {
  SHIFT = 'shift',
  APPLICATION = 'application',
}

export const enum IntegrationSourceEnum {
  EXTERNAL = 'external', // from Ty-Zasilkovna
  INTERNAL = 'internal', // from Ty-Brigady
}

export const enum MailResult {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export const enum SalaryLimitType {
  AVOID_TAXES = 'AVOID_TAXES',
  NONE = 'NONE',
}

export const enum PersonBanType {
  YOUNG = 'young',
  OLD = 'old',
}

export enum ForeignerOrigin {
  EU_CITIZEN = 'eu_citizen',
  EHP_CITIZEN = 'ehp_citizen',
  SWITZERLAND_CITIZEN = 'switzerland_citizen',
  GBR_CITIZEN = 'gbr_citizen',
  EU_FAMILY_CITIZEN = 'eu_family_citizen',
  TEMPORARY_PROTECTION_HOLDER_UKRAINE = 'temporary_protection_holder_ukraine',
  FOREIGNER_BY_98_LETTER_A = 'foreigner_by_98_letter_a',
  FOREIGNER_BY_98_LETTER_B = 'foreigner_by_98_letter_b',
  FOREIGNER_BY_98_LETTER_C = 'foreigner_by_98_letter_c',
  FOREIGNER_BY_98_LETTER_D = 'foreigner_by_98_letter_d',
  FOREIGNER_BY_98_LETTER_E = 'foreigner_by_98_letter_e',
  FOREIGNER_BY_98_LETTER_F = 'foreigner_by_98_letter_f',
  FOREIGNER_BY_98_LETTER_G = 'foreigner_by_98_letter_g',
  FOREIGNER_BY_98_LETTER_H = 'foreigner_by_98_letter_h',
  FOREIGNER_BY_98_LETTER_I = 'foreigner_by_98_letter_i',
  FOREIGNER_BY_98_LETTER_J = 'foreigner_by_98_letter_j',
  FOREIGNER_BY_98_LETTER_K = 'foreigner_by_98_letter_k',
  FOREIGNER_BY_98_LETTER_L = 'foreigner_by_98_letter_l',
  FOREIGNER_BY_98_LETTER_M = 'foreigner_by_98_letter_m',
  FOREIGNER_BY_98_LETTER_N = 'foreigner_by_98_letter_n',
  FOREIGNER_BY_98_LETTER_O = 'foreigner_by_98_letter_o',
  FOREIGNER_BY_98_LETTER_P = 'foreigner_by_98_letter_p',
  FOREIGNER_BY_98_LETTER_Q = 'foreigner_by_98_letter_q',
  FOREIGNER_BY_98_LETTER_R = 'foreigner_by_98_letter_r',
  FOREIGNER_BY_98_LETTER_S = 'foreigner_by_98_letter_s',
  FOREIGNER_BY_98_A = 'foreigner_by_98_a',
}

export const enum EmailSenderAddress {
  NOREPLY = 'noreply@tymbe.com',
  ADMIN = 'admin@tymbe.com',
}

export const enum VacationApprovedType {
  TAKE = 'take',
  GIVE = 'give',
}

export { CountryCodes };
export const validCzechCountries = [CountryCodes.CZE];
export const NON_EU_COUNTRIES = [
  CountryCodes.UKR,
  CountryCodes.RUS,
  CountryCodes.KAZ,
  CountryCodes.BLR,
  CountryCodes.MDA,
  CountryCodes.MNG,
  CountryCodes.SRB,
  CountryCodes.KGZ,
  CountryCodes.EGY,
  CountryCodes.XXK,
  CountryCodes.UZB,
  CountryCodes.MKD,
  CountryCodes.MLI,
];

export const EU_COUNTRIES = [
  CountryCodes.CZE,
  CountryCodes.SVK,
  CountryCodes.POL,
  CountryCodes.ROU,
  CountryCodes.HUN,
  CountryCodes.DEU,
  CountryCodes.ITA,
  CountryCodes.CYP,
  CountryCodes.HRV,
  CountryCodes.BGR,
];

export const enum ShiftType {
  REGULAR = 'regular',
  VACATION = 'vacation',
}

export const EEA_ONLY_COUNTRY_CODES = [
  CountryCodes.ISL,
  CountryCodes.LIE,
  CountryCodes.NOR,
];

export const EU_EEA_SWISS_COUNTRY_CODES = [
  ...EU_COUNTRIES,
  ...EEA_ONLY_COUNTRY_CODES,
  CountryCodes.CHE,
];

export const EuEeaSwitzCountryCode = Object.fromEntries(
  EU_EEA_SWISS_COUNTRY_CODES.map((code) => [code, code])
) as {
  [TKey in (typeof EU_EEA_SWISS_COUNTRY_CODES)[number]]: TKey;
};
export type EuEeaSwitzCountryCode =
  (typeof EuEeaSwitzCountryCode)[keyof typeof EuEeaSwitzCountryCode];

export enum SurveyCategorySlug {
  referralSource = 'referral_source',
  tymbeAnketa = 'tymbe_anketa',
}

export enum BlockReasons {
  PREVENTION = 'prevention',
  LOW_PERFORMANCE = 'low_performance',
  INAPPROPRIATE_BEHAVIOUR = 'inappropriate_behaviour',
  VIOLATION_OF_DISCIPLINE = 'violation_of_discipline',
  THEFT = 'theft',
  OTHER = 'other',
  HISTORICAL_BLOCK = 'historical_block',
}

export const enum MpsvReportStatus {
  OK = 'ok',
  MANUAL = 'manual',
  STORNO = 'storno',
  CHANGE = 'change',
  ERROR = 'error',
}

export const enum MpsvReportResponseStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  ERROR = 'error',
}

export const enum ApiLock {
  MPSV_REPORT = 'mpsv_report',
}

export const enum PaymentStatusType {
  PAYOUT_STATUS = 'payout_status',
}

export enum AbsenceCategory {
  HEALTH_REASONS = 'health_reasons',
  FAMILY_REASONS = 'family_reasons',
  TRAFFIC = 'traffic',
  OTHER = 'other',
}

export enum AbsenceState {
  PROCESSING = 'processing',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CLARIFICATION_NEEDED = 'clarification_needed',
  SYSTEM_APPROVED = 'system_approved',
  SYSTEM_REJECTED = 'system_rejected',
  // State that's used in dummy excuses for canceled_late applications and unexcused_absence attendances
  // that were created before new absence system was introduced
  HISTORICAL_UNKNOWN = 'historical_unknown',
}
