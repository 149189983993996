import { BrowserTracing, init as initSentry } from '@sentry/react';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/cs_CZ';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as StoreProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import Store from './data/store';
import queryClient from './queryClient';
import * as serviceWorker from './serviceWorker';

import 'moment/locale/cs';

import getResponseArray from '@tymbe/utils/getResponseArray';

if (['production', 'stage'].includes(import.meta.env.MODE)) {
  initSentry({
    dsn: 'https://0732540b862440d28f20dd73a78ca66f@o4503999020531712.ingest.sentry.io/4503999175524352',
    environment: import.meta.env.MODE,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const eageredProperties = {
  person: 'person',
  employer: 'company',
  company: 'company',
  mpsvReport: 'mpsv-report',
};

// Iterates over data and caches eagered properties into react-query cache
const cacheReactQueryResponseEagers = (data) => {
  if (!data) return;
  if (Array.isArray(data)) {
    data.forEach(cacheReactQueryResponseEagers);
  } else if (typeof data === 'object') {
    Object.entries(data).forEach(([key, value]) => {
      if (Object.keys(eageredProperties).includes(key)) {
        queryClient.setQueryData([eageredProperties[key], value.id], value);
      }
    });
  }
};

// Remove tymbe preloader
const removeTymbeSpinner = () => {
  const spinnerContainer = document.getElementById('spinner-container');
  if (spinnerContainer) {
    spinnerContainer.style.opacity = '0';
    setTimeout(() => spinnerContainer.remove(), 500);
  }
};
queryClient.setDefaultOptions({
  queries: {
    onSuccess: (res) => {
      const data = res.data || res;
      cacheReactQueryResponseEagers(data);
    },
    staleTime: 1000 * 5,
    refetchOnWindowFocus: false,
  },
});

const persistor = persistStore(Store);
moment.locale('cs');
moment.updateLocale('cs', {
  months: {
    isFormat: /^MMMM/,
  },
});
ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider contextSharing client={queryClient}>
    <StoreProvider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </PersistGate>
    </StoreProvider>
    {import.meta.env.MODE !== 'test' && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>,
  // </React.StrictMode>
  document.getElementById('root'),
);
removeTymbeSpinner();

// If you want to enable PWA, switch
// unregister() to register() below.
serviceWorker.unregister();
