import { canceledLateHeaders } from './components/headers/canceledLateHeaders';
import { UnexcusedAbsences } from './components/UnexcusedAbsences';

export const canceledLateQueryId = 'admin/canceledLate';

export const CanceledLateTab = () => {
  return (
    <UnexcusedAbsences
      query={{
        $modify: {'canceledLateAbsences': true},
      }}
      headers={canceledLateHeaders}
      queryId={canceledLateQueryId}
      dateColumnGetter={(application) => application.updated_at}
    />
  );
};
