import { FieldProps, useField } from 'informed';
import { GroupBase, PropsValue } from 'react-select';
import { AsyncPaginateProps, LoadOptions, AsyncPaginate } from 'react-select-async-paginate';
import './TyInput.css';

import CreatableAsyncPaginate from './CreatableAsyncPaginate';
import CustomOption from './CustomOption';
import TyInputError from './TyInputError';

import classNames from 'classnames';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

type Props<OptionType, Group extends GroupBase<OptionType>, Additional, IsMulti extends boolean> =
  AsyncPaginateProps<OptionType, Group, Additional, IsMulti>;
type DivProps = { label?: string & React.HTMLProps<HTMLDivElement> };

export interface DefaultAsyncSelectOption {
  value: unknown,
  label: string,
}

export const isDefaultSelectOption = (arg: unknown): arg is DefaultAsyncSelectOption =>
  typeof arg === 'object'
  && !!arg && Object.hasOwn(arg, 'label')
  && Object.hasOwn(arg, 'value')
  && typeof arg.label === 'string';

export type TyAsyncSelectProps<Fields extends object,
  Option,
  Group extends GroupBase<Option>,
  Additional,
  IsMulti extends boolean> =
  Omit<Props<Option, Group, Additional, IsMulti>, keyof FieldProps<DivProps, Option | Option[], Fields>>
  & FieldProps<DivProps, Option | Option[], Fields>
  & {
    autoSelectSingle?: boolean,
    isCreatable?: boolean,
    createOptionPosition?: 'first' | 'last',
    formatNewOption?: (inputValue: string) => Option | null;
    formatCreateLabel?: (inputValue: string) => string;
    plain?: boolean,
    customSelectComponents?: Partial<SelectComponents<Option, IsMulti, Group>>,
  };

const TyAsyncSelect =
  <Fields extends object, Option, Group extends GroupBase<Option>, Additional, IsMulti extends boolean = false>(
    props: TyAsyncSelectProps<Fields, Option, Group, Additional, IsMulti>,
  ) => {
    const {
      fieldState,
      userProps,
      fieldApi,
      render,
    } = useField<typeof props, PropsValue<Option>>({ ...props, validateOn: props.autoSelectSingle ? 'change' : 'blur' });
    const {
      id,
      label,
      className,
      placeholder = '',
      loadOptions,
      isClearable = false,
      noOptionsMessage = () => 'Žádné výsledky',
      loadingMessage = () => 'Načítání',
      required = false,
      validate,
      isDisabled = false,
      defaultValue,
      autoSelectSingle = false,
      isCreatable = false,
      formatNewOption,
      formatCreateLabel,
      createOptionPosition = 'last',
      plain = false,
      ...rest
    } = userProps;
    const { error, showError, focused, value } = fieldState;
    const { setValue, setTouched, setFocused } = fieldApi;

    const isFocusedClassName = (classN: string) => {
      if (focused) return classN;
      if (rest.isMulti && (value as unknown[])?.length) return classN;
      if (!rest.isMulti && value) return classN;
      return '';
    };

    const innerLoadOptions: LoadOptions<Option, Group, Additional> = async (search, prevOptions, additional) => {
      if (isDisabled) return { options: [], hasMore: false };
      if (search || !autoSelectSingle) return loadOptions(search, prevOptions, additional);
      const res = await loadOptions(search, prevOptions, additional);
      if (res.options[0] && Object.keys(res.options[0]).every((key) => ['options', 'label'].includes(key))) {
        return res;
      }
      if (res.options.length === 1) {
        setValue(res.options[0] as Option);
      }
      return res;
    };

    const isDisabledClassName = isDisabled ? 'ty_input_field--is-disabled' : '';

    const PaginateComp = isCreatable ? CreatableAsyncPaginate : AsyncPaginate;

    const compClassName = classNames({
      'ty_select__container ty_input_select': !plain,
    }, isFocusedClassName('ty_input_select_focused'));

    return render(
      <div className={`ty_input_field ty_select ${showError && error ? 'ty_input_error' : ''} ${className || ''} ${isDisabledClassName}`}>
        <PaginateComp<Option, Group, Additional, IsMulti>
          {...rest}
          inputId={id}
          value={value || null}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={compClassName}
          classNamePrefix="ty_select"
          components={props.customSelectComponents ?? {
            // we have no control over naming convention in external library
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Option: CustomOption,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            DropdownIndicator: () => null,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            if (e === value) return;
            setValue(e);
          }}
          onCreateOption={(e) => {
            if (!formatNewOption) return;
            const res = formatNewOption(e);
            if (!res) return;
            setValue(res);
          }}
          onBlur={(e) => {
            setFocused(false, e);
            setTouched(true, e);
          }}
          onFocus={(e) => setFocused(true, e)}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          maxMenuHeight={250}
          isClearable={isClearable}
          loadOptions={innerLoadOptions}
          isDisabled={isDisabled}
          openMenuOnFocus
          aria-invalid={showError}
          aria-describedby={`${id}-error`}
          formatCreateLabel={formatCreateLabel}
          createOptionPosition={createOptionPosition}
        />
        {label ? <label htmlFor={id}>{label}</label> : null}
        <TyInputError
          fieldState={fieldState}
          required={required}
          validate={validate}
        />
      </div>,
    );
  };

export default TyAsyncSelect as typeof TyAsyncSelect;
