/* eslint-disable @typescript-eslint/ban-ts-comment */
import './timeline.css';
import moment, { MomentInput } from 'moment';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import feathersClient from '../../../../apiClient';

interface ManShiftRecord {
  time: moment.Moment;
  operation: string;
  data: any;
}
const ShiftTimeline = () => {
  const { shiftId } = useParams();

  const { data: shift } = useQuery(['shiftTimeline', shiftId], async () =>
    feathersClient.service('shift').get(shiftId!, {
      query: {
        $eager: '[manShift(withDeleted).[createdBy, deletedBy]]',
      },
    }), { enabled: !!shiftId, refetchOnWindowFocus: false });

  const manshiftUpdatesByUser: Record<number, ManShiftRecord[]> = {};

  const createTimelineEntry = <D extends object>(operation: string, time: MomentInput, data: D) => ({
    time: moment(time),
    operation,
    data,
  });

  const enum TimelineOperation {
    CREATE = 'create',
    DELETE = 'delete',
  }

  shift?.manShift?.forEach((row) => {
    // group actions by user, if created_by or deleted_by is null, group together with key 0
    const createdById = row.created_by ?? 0;
    const deletedById = row.deleted_by ?? 0;

    if (row.created_at) {
      if (!manshiftUpdatesByUser[createdById]) {
        manshiftUpdatesByUser[createdById] = [];
      }

      manshiftUpdatesByUser[createdById].push(
        createTimelineEntry(TimelineOperation.CREATE, row.created_at, { manShiftId: row.id, person: row.createdBy }),
      );
    }

    if (row.deleted_at) {
      if (!manshiftUpdatesByUser[deletedById]) {
        manshiftUpdatesByUser[deletedById] = [];
      }

      manshiftUpdatesByUser[deletedById].push(
        createTimelineEntry(TimelineOperation.DELETE, row.deleted_at, { manShiftId: row.id, person: row.deletedBy }),
      );
    }
  });

  // create clusters of users and times of actions
  const clustersByUserAndTime: ManShiftRecord[][] = [];

  Object.entries(manshiftUpdatesByUser).forEach(([key, userUpdates]) => {
    // sort by time of the action in every user group
    const sortedUserUpdates = userUpdates.sort((a, b) => moment(a.time).valueOf() - moment(b.time).valueOf());

    sortedUserUpdates.forEach((update, i) => {
      if (i === 0) {
        clustersByUserAndTime.push([update]);
        return;
      }
      // put in one cluster if the time difference is smaller than 60 seconds
      const timeDifference = moment.duration((moment(update.time)).diff(moment(sortedUserUpdates[i - 1].time))).as('minutes');

      if (timeDifference <= 1) {
        clustersByUserAndTime[clustersByUserAndTime.length - 1].push(update);
      } else {
        clustersByUserAndTime.push([update]);
      }
    });
  });
  // sort clusters by time
  clustersByUserAndTime.sort((a, b) => moment(a[0].time).valueOf() - moment(b[0].time).valueOf());

  const manshiftCountInCluster = (cluster: ManShiftRecord[]) => {
    const count = cluster?.map(
      (row) => (row.operation === TimelineOperation.DELETE ? -1 : 1),
    ).reduce((a, b) => a + b, 0);
    if (count > 0) return `+${count}`;
    return count;
  };

  return (
    <ul className="timeline">
      <li key={`${clustersByUserAndTime[0]?.[0].time} ${clustersByUserAndTime[0]?.[0].data.person?.last_name}`}>
        <div className="timeline-start">
          <b>Vznik objednávky</b>
          <br />
          {moment(clustersByUserAndTime[0]?.[0].time).format('DD.MM.YYYY')}
          <br />
          {moment(clustersByUserAndTime[0]?.[0].time).format('HH:mm')}
        </div>
        <div className="timeline-middle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
          </svg>

        </div>
        <div className="timeline-end timeline-box flex">
          {manshiftCountInCluster(clustersByUserAndTime[0])}
          <br />
          {clustersByUserAndTime[0]?.[0].data.person
            ? (
              <>{clustersByUserAndTime[0]?.[0].data.person?.first_name}
                <br />
                {clustersByUserAndTime[0]?.[0].data.person?.last_name}
              </>
            )
            : <>neznámý <br /> uživatel</>}
        </div>
        <hr />
      </li>
      {clustersByUserAndTime.slice(1).map((el) => (
        manshiftCountInCluster(el) !== 0
        && (
          <li key={`${el[0].time} ${el[0].data.person?.last_name}`}>
            <hr />
            <div className="timeline-start">
              {moment(el[0]?.time).format('DD.MM.YYYY')}
              <br />
              {moment(el[0].time).format('HH:mm')}
            </div>
            <div className="timeline-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="timeline-end timeline-box flex">
              {manshiftCountInCluster(el)}
              <br />
              {el[0].data.person
                ? (
                  <>
                    {el[0].data.person.first_name}
                    <br />
                    {el[0].data.person.last_name}
                  </>
                )
                : <>neznámý <br /> uživatel</>}

            </div>
            <hr />
          </li>
        )
      ))}
      <li key={shift?.start_time}>
        <hr />
        <div className="timeline-start">
          <b>Začátek směny</b>
          <br />
          {moment(shift?.start_time).format('DD.MM.YYYY')}
          <br />
          {moment(shift?.start_time).format('HH:mm')}
        </div>
        <div className="timeline-middle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
          </svg>

        </div>
        <div className="timeline-end timeline-box flex">
          Celkový
          <br />
          počet
          <br />
          {shift?.manShift?.filter((row) => row.deleted_at === null).length}
        </div>
      </li>
    </ul>
  );
};

export default ShiftTimeline;
