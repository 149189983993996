import { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import { ErrorAlert } from '../../../components/alerts';
import Container from '../../../containers';
import AuthenticateContainer from '../../../containers/authenticate';
import { Roles } from '../../../utils/enums';

const SignInPage = () => {
  const intl = useIntl();
  const [disabledForm, setFormDisabled] = useState(false);

  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect') || '/shift';

  const location = useLocation();
  const url = location.pathname;
  const [title, setTitle] = useState(' ');

  const { mutateAsync: checkPersonData } = useMutation(
    ['check-person-data'],
    async ({ id }) => feathersClient.service('person-data').find(id),
  );

  const renderTitle = useMemo(() => (pathname) => {
    if (pathname.includes('signin')) {
      setTitle(intl.formatMessage({ defaultMessage: 'Vítejte před branami Tymbe, znáte kouzelné heslo?' }));
    } else if (pathname.includes('finish-registration')) {
      // eslint-disable-next-line max-len
      setTitle(intl.formatMessage({ defaultMessage: 'Zadej své přihlašovací údaje a dokonči registraci!' }));
    } else {
      setTitle(intl.formatMessage({ defaultMessage: 'Vítejte před branami Tymbe, znáte kouzelné heslo?' }));
    }
  }, [intl]);

  const redirectUser = async (newUser) => {
    if (newUser.role?.some((x) => [
      Roles.SUPER_ADMIN,
      Roles.TYMBE_ADMIN,
      Roles.ADMIN,
      Roles.TYMBE_COORDINATOR,
      Roles.COMPANY,
      Roles.BRANCHOFFICE_MANAGER,
      Roles.BRANCHOFFICE_SUPERVISOR,
      Roles.SHIFT_SUPERVISOR,
    ].includes(x.slug))) {
      history(redirect);
    } else if (newUser.role?.some((x) => x.slug === Roles.TYMBER)) {
      const pd = await checkPersonData({ id: newUser.person_id });
      if (pd.data.length > 0) {
        window.location.assign(import.meta.env.CLIENT_BRIGADY_URL);
      } else {
        history('/identity-validation');
      }
    }
  };

  const onError = (err) => {
    if (err.message === 'Not verified') {
      history('/verify/activate-account');
    } else if (err.message === 'Login company banned' && err.code === 401) {
      ErrorAlert(intl.formatMessage({ defaultMessage: 'Chyba. Vaše firma je zablokovaná' }), 10);
    } else if (err.message === 'Invalid login' || err.code === 401) {
      ErrorAlert(intl.formatMessage({ defaultMessage: 'Chyba. Nesprávné přihlašovací údaje' }), 10);
    } else {
      ErrorAlert(`${err.message || ''}. ${intl.formatMessage({ defaultMessage: 'Nastala chyba. Zkuste znovu' })}`, 10);
    }
  };

  const onFinish = async (credentials) => {
    setFormDisabled(true);
    const authData = { username: credentials.email.trim(), password: credentials.password.trim() };

    try {
      const res = await feathersClient.authenticate({
        strategy: 'local',
        ...authData,
      });
      if (res.code === 401) {
        onError(res);
      } else {
        redirectUser(res.user);
      }
    } catch (err) {
      onError(err);
    }
    setFormDisabled(false);
  };

  const onFinishFailed = (err) => {
    ErrorAlert(err);
  };

  useEffect(() => {
    renderTitle(url);
  }, [url, renderTitle]);

  return (
    <Container
      header
      iconcolor="#B3CA1F"
      background="#F3F5FD"
      footer={false}
    >
      <AuthenticateContainer
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        title={title}
        disabled={disabledForm}
      />
    </Container>
  );
};

export default SignInPage;
