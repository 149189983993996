import { Paginated, Query } from '@feathersjs/feathers';
import { CreditTransactionData } from '@tymbe/schema/credit-transaction.interface';
import moment, { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import CreditRow from './CreditRow';
import CreditsCalculation from './CreditsCalculation';
import feathersClient from '../../../apiClient';
import { TableRangePicker } from '../../../components/inputs';
import Card from '../../../components/Layout/Card';
import { TablePagination, useTablePagination } from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';

export type DateRangeType = {
  from: moment.Moment;
  to: moment.Moment;
};

const UserCredits = () => {
  const { id: personId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setPageParams, pageStart: paginationStart, pageSize: paginationPageSize } = useTablePagination();
  const startOfCurrentMonth = moment().tz('europe/prague').startOf('month');
  const endOfCurrentMonth = moment().tz('europe/prague').endOf('month');

  const [dateRange, setDateRange] = useState<DateRangeType>({
    from: startOfCurrentMonth,
    to: endOfCurrentMonth,
  });
  const [query, setQuery] = useState<Query>({
    'credit_transaction.person_id': personId,
    $leftJoinRelation: '[attendance.application.shift]',
    $eager:
      '[attendance.application.shift, personLiability(withDeleted), paymentRequest, paymentTransaction]',
    $sort: { created_at: -1 },
    $modify: 'withDeleted',
    $skip: paginationStart,
    $limit: paginationPageSize,
    $or: [
      {
        $and: [
          { 'attendance:application:shift.start_time': { $gte: dateRange.from.toISOString() } },
          { 'attendance:application:shift.end_time': { $lte: dateRange.to.toISOString() } },
        ],
      },
      {
        $and: [
          { 'credit_transaction.created_at': { $gte: dateRange.from.toISOString() } },
          { 'credit_transaction.created_at': { $lte: dateRange.to.toISOString() } },
        ],
      },
    ],
  });

  const { data: creditTransaction } = useQuery<
  Paginated<CreditTransactionData>
  >(['credit-transaction', query], async () =>
    feathersClient.service('credit-transaction').find({ query }));

  const onSetDateRange = ({ to, from }: DateRangeType) => {
    // Do not request API, if input has not changed at all
    if (to === dateRange?.to && from === dateRange?.from) return;
    setDateRange({ from, to });
    setSearchParams((params) => {
      params.set('page', '1');
      return params;
    });
  };

  useEffect(() => {
    const q = {
      'credit_transaction.person_id': personId,
      $leftJoinRelation: '[attendance.application.shift]',
      $eager:
        '[attendance.application.shift, personLiability(withDeleted), paymentRequest, paymentTransaction]',
      $sort: { created_at: -1 },
      $modify: 'withDeleted',
      $skip: paginationStart,
      $limit: paginationPageSize,
      $or: [
        {
          $and: [
            { 'attendance:application:shift.start_time': { $gte: dateRange.from.toISOString() } },
            { 'attendance:application:shift.end_time': { $lte: dateRange.to.toISOString() } },
          ],
        },
        {
          $and: [
            { 'credit_transaction.created_at': { $gte: dateRange.from.toISOString() } },
            { 'credit_transaction.created_at': { $lte: dateRange.to.toISOString() } },
          ],
        },
      ],
    };
    setQuery(q);
  }, [paginationPageSize, paginationStart, personId, dateRange]);

  return (
    <>
      <Card>
        <div>
          <TableRangePicker
            className="rounded-xl p-3"
            onDateChange={(timeArray: Date[] | null) => {
              if (!timeArray) return;

              onSetDateRange({
                from: moment(timeArray[0]),
                to: moment(timeArray[1]),
              });
            }}
            value={[dateRange?.from, dateRange?.to]}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defaultValue={[moment().startOf('month'), moment().endOf('month')]}
            ranges={{
              Dnes: [moment(), moment()],
              'Tento týden': [moment().startOf('week'), moment().endOf('week')],
              'Příští týden': [
                moment().add(1, 'weeks').startOf('week'),
                moment().add(1, 'weeks').endOf('week'),
              ],
              'Tento měsíc': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }}
          />
        </div>
      </Card>
      {personId && (
        <>
          <CreditsCalculation
            personId={personId}
            from={dateRange.from}
            to={dateRange.to}
          />
          <Card>
            <div>
              {creditTransaction?.data?.map((credit: CreditTransactionData) => (
                <CreditRow credit={credit} />
              ))}
            </div>
            <TablePagination
              {...setPageParams()}
              rowsCount={creditTransaction?.total}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default UserCredits;
